import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Przelewy24Methods from '@components/images/Przelewy24Methods';
import BasePage from './layouts/BasePage';
import Heading from '../components/Heading';
import {
  usePackage, useSettings,
} from '../hooks';
import SectionLoading from '../components/SectionLoading';
import SuperButton from '../components/SuperButton';
import { payNow } from '../api';

export default function CheckoutPage() {
  const { id } = useParams();
  const { isLoading, settings } = useSettings();
  const { isLoading: isPackageLoading, pack } = usePackage(id);
  const [isProcessingPayment, setProcessingPayment] = useState(false);

  const handlePayment = () => {
    setProcessingPayment(true);

    payNow({
      id: parseInt(id),
    }).then((data) => {
      if (data?.errors) return toast.info(data.message);

      // Redirect to PayU gateway.
      window.location.href = data.redirect_url;
    }).catch((err) => {
      if (err.response?.data?.message) toast.error(err.response?.data?.message);
      else toast.error(err);
    }).finally(() => {
      setProcessingPayment(false);
    });
  };

  if (isLoading || isPackageLoading || !Object.keys(pack).length) {
    return <SectionLoading center />;
  }

  return (
    <BasePage>
      <Heading title="Koszyk" subTitle="Strona główna > Koszyk" />
      <section className="container py-5">
        <div className="row">
          <div className="col-md-8">
            <Card border="light mb-4 rounded-4 shadow-sm">
              <Card.Body className="p-4">
                <div className="row">
                  <div className="col">
                    <Przelewy24Methods />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card border="light" className="mb-4 rounded-4 shadow-sm">
              <Card.Title className="p-3 h5">Podsumowanie zamówienia</Card.Title>
              <Card.Body className="p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    Pakiet
                    {' '}
                    {pack?.name}
                    {' '}
                    (netto)
                  </span>
                  <span>
                    {/* eslint-disable-next-line no-unsafe-optional-chaining */}
                    {(pack?.price / (1 + 23 / 100)).toFixed(2)}
                    {' '}
                    {settings?.CURRENCY_SYMBOL}
                  </span>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    VAT
                    {' '}
                    (23%)
                  </span>
                  <span>
                    {/* eslint-disable-next-line no-unsafe-optional-chaining */}
                    {(pack?.price - (pack?.price / (1 + 23 / 100)).toFixed(2)).toFixed(2)}
                    {' '}
                    {settings?.CURRENCY_SYMBOL}
                  </span>
                </div>
                <hr />
                <Card.Title>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <span>Do zapłaty:</span>
                    {' '}
                    <span>
                      {pack?.price}
                      {' '}
                      {settings?.CURRENCY_SYMBOL}
                    </span>
                  </div>
                </Card.Title>
              </Card.Body>
            </Card>
            <SuperButton onClick={handlePayment} className="btn btn-primary btn-lg btn-block" isLoading={isProcessingPayment}>Przejdź do płatności</SuperButton>

            <Card border="light" className="mb-4 rounded-4 shadow-sm mt-4">
              <Card.Body className="p-4">
                <div className="row row-cols-2">
                  <div className="col-2">
                    <FontAwesomeIcon icon={faShieldHalved} className="display-5" />
                  </div>
                  <div className="col-10">
                    <Card.Title className="h5">OCHRONA SSL</Card.Title>
                    <span className="text-muted">Twoje informacje są chronione szyfrowaniem 256-bit.</span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </section>
    </BasePage>
  );
}
