import { memo, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '@hooks/auth';
import TablerIcon from '@components/TablerIcon';
import { IconShieldLockFilled } from '@tabler/icons-react';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPasswordForm from './ForgotPasswordForm';

export default memo(({
  onLogin,
  onRegister,
  onLoginRedirectTo,
  onRegisterRedirectTo,
  isGoogleInitiated,
}) => {
  const [currentForm, setCurrentForm] = useState('REGISTER'); // LOGIN | REGISTER | REGISTERED | FORGOT_PASSWORD

  const navigate = useNavigate();
  const { isAuthenticated } = useUser();

  useEffect(() => {
    if (isAuthenticated) {
      if (typeof onLogin === 'function') return onLogin();
      if (onLoginRedirectTo) return navigate(onLoginRedirectTo);
    }
  }, [isAuthenticated]);

  return (
    <>
      { currentForm === 'LOGIN' ? (
        <>
          <LoginForm
            onLogin={onLogin}
            setCurrentForm={setCurrentForm}
            onLoginRedirectTo={onLoginRedirectTo}
            isGoogleInitiated={isGoogleInitiated}
          />
          <span>
            Nie masz jeszcze konta?
            {' '}
            <Link onClick={() => setCurrentForm('REGISTER')}>Zarejestruj się</Link>
          </span>

          <div
            className="mt-3"
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px',
            }}
          >
            <div style={{ color: '#00b373' }}>
              <TablerIcon icon={IconShieldLockFilled} size={30} />
            </div>
            <small>Twoje informacje są chronione szyfrowaniem 256-bit.</small>
          </div>
        </>
      ) : currentForm === 'REGISTER' ? (
        <>
          <RegisterForm
            onRegister={onRegister}
            setCurrentForm={setCurrentForm}
            onRegisterRedirectTo={onRegisterRedirectTo}
            isGoogleInitiated={isGoogleInitiated}
          />
          <span>
            Masz już konto?
            {' '}
            <Link onClick={() => setCurrentForm('LOGIN')}>Zaloguj się</Link>
          </span>
          <div
            className="mt-3"
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px',
            }}
          >
            <div style={{ color: '#00b373' }}>
              <TablerIcon icon={IconShieldLockFilled} size={30} />
            </div>
            <small>Twoje informacje są chronione szyfrowaniem 256-bit.</small>
          </div>
        </>
      ) : currentForm === 'REGISTERED' ? (
        <>
          <p>
            Dziękujemy za założenie konta! Aby zweryfikować swój adres e-mail, kliknij w link, który przesłaliśmy
            na podany adres e-mail.
          </p>
        </>
      ) : (
        <ForgotPasswordForm setCurrentForm={setCurrentForm} />
      )}
    </>
  );
});
