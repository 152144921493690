import axiosApi from './axiosApi';

export async function getSettings() {
  const req = await axiosApi.get('/settings');

  return req.data;
}

export async function getDashboardSettings() {
  const req = await axiosApi.get('/admin/settings');
  return req.data;
}

export function uploadFile(uri, file, { onUploadProgress }) {
  const formData = new FormData();

  formData.append('file', file);

  return axiosApi.post(uri, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

export function uploadFiles(uri, files, { onUploadProgress }) {
  const formData = new FormData();

  // eslint-disable-next-line array-callback-return
  files.map((file) => {
    formData.append('file[]', file);
  });

  return axiosApi.post(uri, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

export async function getPackages() {
  const req = await axiosApi.get('/packages');
  return req.data;
}

export async function getAvailablePaymentMethods() {
  const req = await axiosApi.get('/payment-methods');
  return req.data;
}

export async function payNow(data) {
  const req = await axiosApi.post('/checkout', data);
  return req.data;
}

export async function payGoogle(data) {
  const req = await axiosApi.post('/google-checkout', data);
  return req.data;
}

export async function shareBonus(data) {
  const req = await axiosApi.post('/user/share-bonus', data);
  return req.data;
}

export async function contactUs(data) {
  const req = await axiosApi.post('/contact', data);
  return req.data;
}

export async function performanceMetrics(data) {
  const req = await axiosApi.post('/performance', data);
  return req.data;
}

export async function getpages() {
  const req = await axiosApi.get('/pages');

  return req.data;
}

export async function getPage(slug) {
  const req = await axiosApi.get(`/page/${slug}`);
  return req.data;
}
