import Zoom from 'react-medium-image-zoom';
import Example1 from '@images/examples/example1-normal.png';
import Example2 from '@images/examples/example2-normal.png';
import Example3 from '@images/examples/example3-geometry.png';
import Example4 from '@images/examples/example4-handwriten.png';
import Example5 from '@images/examples/example5-whatswrong.png';
import Example6 from '@images/examples/example6-english.png';
import BasePage from './layouts/BasePage';
import Heading from '../components/Heading';

import 'react-medium-image-zoom/dist/styles.css';

export default function ExamplePage() {
  const images = [Example1, Example2, Example3, Example4, Example5, Example6];
  const descs = [
    'Zadanie maturalne z matematyki z graficznym układem współrzędnych',
    'Zadanie maturalne z matematyki z układem równań',
    'Zadanie z geometrii',
    'Zadanie ręcznie zapisane',
    'Pytanie uzupełniające do zadania o poprawę własnego rozwiązania',
    'Zadanie z j. angielskiego',
  ];

  return (
    <BasePage>
      <Heading title="Pzykłady działania" subTitle="Strona główna > Przykłady" />

      <section className="container">
        <div className="container my-4">
          <div className="row">
            {images.map((imageSrc, index) => (
              <div className="col-12 col-lg-4 mb-3 text-center" key={index}>
                <h5>{descs[index]}</h5>
                <Zoom>
                  <img
                    src={imageSrc}
                    alt={descs[index]}
                    style={{
                      maxWidth: '320px',
                    }}
                  />
                </Zoom>
              </div>
            ))}
          </div>
        </div>
      </section>
    </BasePage>
  );
}
