import { useEffect } from 'react';
import { performanceMetrics } from '../api';

export function usePerformanceMetrics() {
  useEffect(() => {
    const round = (value) => Math.round(value * 100) / 100;

    const collectMetrics = () => {
      if (!('performance' in window)) return;

      const navigation = performance.getEntriesByType('navigation')[0];
      if (!navigation) return;

      const metrics = {
        // Podstawowe
        timeToFirstByte: round(navigation.responseStart - navigation.requestStart),
        domContentLoaded: round(navigation.domContentLoadedEventEnd - navigation.startTime),
        domInteractive: round(navigation.domInteractive - navigation.startTime),
        fullLoad: round(navigation.loadEventEnd - navigation.startTime),

        // Rozszerzone
        redirectTime: round(navigation.redirectEnd - navigation.redirectStart),
        dnsLookupTime: round(navigation.domainLookupEnd - navigation.domainLookupStart),
        tcpConnectTime: round(navigation.connectEnd - navigation.connectStart),
        responseTime: round(navigation.responseEnd - navigation.responseStart),

        // Info
        url: navigation.name,
        transferSize: navigation.transferSize,
        type: navigation.type,
        startTime: round(navigation.startTime),
        userAgent: navigator.userAgent,
      };

      performanceMetrics(metrics);
    };

    if (document.readyState === 'complete') {
      collectMetrics();
    } else {
      window.addEventListener('load', collectMetrics);
      return () => window.removeEventListener('load', collectMetrics);
    }
  }, []);
}
