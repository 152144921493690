import usersRating from '@images/profiles.png';
import { IconStarFilled } from '@tabler/icons-react';
import TablerIcon from '@components/TablerIcon';

export default function UsersRating() {
  return (
    <div style={{
      display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',
    }}
    >
      <img
        style={{ width: '76px', height: '32px', marginRight: '10px' }}
        src={usersRating}
        alt="rating"
      />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{
          color: '#F1BA3B', display: 'flex', justifyContent: 'center', gap: '5px',
        }}
        >
          <TablerIcon icon={IconStarFilled} size={15} />
          <TablerIcon icon={IconStarFilled} size={15} />
          <TablerIcon icon={IconStarFilled} size={15} />
          <TablerIcon icon={IconStarFilled} size={15} />
          <TablerIcon icon={IconStarFilled} size={15} />
        </div>
        <small>
          Używane przez
          {' '}
          <b>+17 tys.</b>
          {' '}
          osób
        </small>
      </div>
    </div>

  );
}
