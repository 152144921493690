import { useEffect, useRef, useState } from 'react';

export default function FacebookLike() {
  const fbContainerRef = useRef(null);
  const [width, setWidth] = useState(312);

  const facebookInit = () => {
    window.FB.XFBML.parse();
  };

  useEffect(() => {
    let resizeTimer;
    const updateWidth = () => {
      if (fbContainerRef.current) {
        const containerWidth = fbContainerRef.current.offsetWidth;
        setWidth(containerWidth);
      }
    };

    const handleResize = () => {
      if (resizeTimer) clearTimeout(resizeTimer);
      resizeTimer = setTimeout(updateWidth, 300);
    };

    updateWidth();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (window.fbSdkReady) {
      facebookInit();
    } else {
      document.addEventListener('fb-sdk-ready', facebookInit, { once: true });
      return () => {
        document.removeEventListener('fb-sdk-ready', facebookInit);
      };
    }
  }, [width]);

  return (
    <div ref={fbContainerRef} className="d-flex justify-content-center w-100">
      <div
        className="fb-like"
        data-href="https://www.facebook.com/profile.php?id=61559520759905"
        data-width={width}
        data-layout="button_count"
        data-action="like"
        data-size="large"
        data-share="true"
      />
    </div>
  );
}
